import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import nightsky_bg from "../assets/images/nightsky_bg.jpg";
import { graphql } from "gatsby";
import Seo from "../components/SEO";
import { iOS } from "../utils/ios";

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;
const HeadingContainer = styled.header`
  display: grid;
  grid-template-columns: 1fr;
  min-height: calc(100vh - 5.8rem);
  text-align: center;
  align-content: center;
  background-image: url(${nightsky_bg});
  background-size: cover;
`;
const Heading = styled.h1`
  font-size: 3rem;
  color: var(--white);
`;

const Subtitle = styled.p`
  font-size: 1.25rem;
  color: var(--white);
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 80% auto;
  grid-template-areas:
    ". services ."
    ". divider ."
    ". testimonials .";
  row-gap: 4rem;
`;

const ServiceContainer = styled.div`
  grid-area: services;
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  align-content: start;
  align-items: center;
  gap: 3rem;
  > h2 {
    font-size: 2rem;
  }
`;

const Services = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: center;
  min-height: 265px;
`;

const TestimonialContainer = styled.div`
  grid-area: testimonials;
  display: grid;
  grid-template-columns: 1fr;

  justify-content: center;
  row-gap: 2rem;
  > h2 {
    font-size: 2rem;
    text-align: center;
  }
`;
const Testimonials = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem;
`;

const Divider = styled.div`
  grid-area: divider;
  background-color: var(--grey);
  height: 1px;
`;

export default function Home({ data }) {
  const testimonies = data.testimonies.nodes;
  const services = data.services.nodes;
  const page = data.page.nodes;

  return (
    <>
      <Seo title="Home" />
      <Layout>
        <HeadingContainer className={iOS() ? "scroll" : "fixed"}>
          {page.map((page) => {
            return (
              <div key={page.id}>
                <Heading>{page.title}</Heading>
                <Subtitle>{page.subtitle}</Subtitle>
              </div>
            );
          })}
        </HeadingContainer>
        <Container>
          <ServiceContainer>
            <h2>Services</h2>
            <Services>
              {services.map(({ id, name, description, slug }) => {
                return ServiceItem(id, name, description, slug);
              })}
            </Services>
          </ServiceContainer>
          <Divider />
          <TestimonialContainer>
            <h2>Testimonials</h2>
            <Testimonials>
              {testimonies.map(({ id, testimony, name, title }) => {
                return TestimonialItem(id, testimony, name, title);
              })}
            </Testimonials>
          </TestimonialContainer>
        </Container>
      </Layout>
    </>
  );
}

const Service = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  text-align: center;
  align-content: center;
  justify-content: space-between;
  max-width: 300px;
  min-height: 250px;
  border: var(--grey) 1px solid;
  border-radius: 0.725rem;
  box-shadow: 1px 1px 1rem var(--grey);
  > p {
    color: var(--grey);
  }
  > a {
    color: var(--blue);
  }
`;

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
`;
function ServiceItem(id, name, description, slug) {
  return (
    <Service key={id}>
      <h3>{name}</h3>
      <p>{description}</p>
      <StyledLink to={`/${slug.current}`}>Learn more</StyledLink>
    </Service>
  );
}

const Testimonial = styled.li`
  max-width: 400px;
  text-align: center;
`;

const Testimony = styled.p`
  font-size: 1.25rem;
  color: grey;
`;

const TestifierContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  > p {
    margin: 0;
  }
`;

const Testifier = styled.p`
  font-size: 1.1rem;
  font-weight: 700;
`;
const TestifierTitle = styled.p`
  color: var(--grey);
  font-style: italic;
`;

function TestimonialItem(id, testimony, name, title) {
  return (
    <Testimonial key={id}>
      <Testimony>{testimony}</Testimony>
      <TestifierContainer>
        <Testifier>{name}</Testifier>
        <TestifierTitle>{title}</TestifierTitle>
      </TestifierContainer>
    </Testimonial>
  );
}

export const query = graphql`
  query MyQuery {
    page: allSanityPageHeader(filter: { page: { eq: "Home" } }, limit: 1) {
      nodes {
        id
        title
        subtitle
      }
    }
    testimonies: allSanityTestimony(filter: { show: { eq: true } }) {
      nodes {
        title
        testimony
        name
        id
      }
    }
    services: allSanityServiceType(filter: { display: { eq: true } }) {
      nodes {
        name
        display
        id
        description
        slug {
          current
        }
      }
    }
  }
`;
// Photo by{" "}
//       <a href="https://unsplash.com/@lucamicheli?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
//         Luca Micheli
//       </a>{" "}
//       on{" "}
//       <a href="https://unsplash.com/s/photos/starry-night?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
//         Unsplash
//       </a>
